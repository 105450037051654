
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ForgotPasswordDto } from "../../core/types/UserTypes";

export default defineComponent({
  name: "forgot-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const forgotPasswordDto = ref<ForgotPasswordDto>({
      email: "",
    });
    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });

    //Form submit function
    const onSubmitForgotPassword = () => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send login request
      store
        .dispatch(Actions.FORGOT_PASSWORD, forgotPasswordDto.value)
        .then(() => {
          router.push({ name: "forgot-password-confirmation" });
        })
        .catch(() => {
          // Alert then login failed
          Swal.fire({
            text: "We could not find your email.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try Again.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      forgotPasswordDto,
      submitButton,
    };
  },
});
